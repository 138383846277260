/*============================================================================*\
  Page - Index
\*============================================================================*/

/* stylelint-disable */

/* Hero Slider
\*==========================================================================*/

.hero--home {
  .container-fluid {
    padding: 0 !important;
  }

  .blog-item .post-thumb:hover img,
  .pb-image:hover img {
    transform: none;
  }
}

.hero--desktop {
  @apply hidden s:block
}
.hero--mobile {
  @apply s:hidden
}

/* Blockquote
\*==========================================================================*/

.section--citation {
  .pb-html {
    * {
      @apply type-h3 font-regular text-blue text-center #{!important};
    }
  }

  @screen l {
    .row {
      display: flex;
    }

    .layout-column {
      display: flex;
      flex-direction: column;
    }

    .addon-box {
      flex: 1;
    }

    .pb-html {
      height: 100%;
    }
  }
}

/* New products
\*==========================================================================*/

.section-features-product {
  background: #fffbeb;

  @media (min-width: 1216px) {
    .container {
      max-width: 1216px !important;
    }
  }
}

/* Community
\*==========================================================================*/

.section--community {
  @screen s {
    .row {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @screen l {
    background-image: url('/themes/bpi/assets/img/home/community-bg.png');
    background-repeat: no-repeat;
    background-position: top 4rem right;
    background-size: auto 30%;
  }

  @media (min-width: 1500px) {
    background-size: auto 48%;
  }

  @media (min-width: 1600px) {
    background-size: auto 64%;
  }

  @screen xxl {
    background-size: auto 37%;
  }

  @media (min-width: 2000px) {
    background-size: auto 47%;
  }

  @media (min-width: 2100px) {
    background-size: auto 64%;
  }
}

/* Entrepreneurs
\*==========================================================================*/

.section--entrepreneurs {
  @screen s {
    background-image: url('/themes/bpi/assets/img/home/entrepreneurs-bg-top.png'),
      url('/themes/bpi/assets/img/home/entrepreneurs-bg-bottom.png');
    background-repeat: no-repeat, no-repeat;
    background-position: top center, bottom center;
    background-size: 100% auto, 100% auto;

    .row {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
